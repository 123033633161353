export default {
    homePageLoad: {
        event: "sell_homepage",
        eventCategory: "Homepage",
        eventAction: "sell_homepage",
        eventLabel: "NA"
    },
    sellDownloadClick: {
        event: "sell_download",
        eventCategory: "Homepage",
        eventAction: "sell_download_click",
        eventLabel: "NA"
    },
    sellAppProceedClick: {
        event: "sell_app_proceed",
        eventCategory: "Homepage",
        eventAction: "sell_app_proceed_click"
    },
    sellFAQsClick: {
        event: "sell_faqs",
        eventCategory: "Homepage",
        eventAction: "sell_faqs_click"
    },
    sellFAQsViewMore: {
        event: "sell_faqs_view_more",
        eventCategory: "Homepage",
        eventAction: "sell_faqs_view_more_click"
    },
    sellGetCarPrice: {
        event: "sell_get_car_price",
        eventCategory: "Seller_questions",
        eventAction: "sell_get_car_price_click"
    },
    sellMake: {
        event: "sell_make",
        eventCategory: "Seller_questions",
        eventAction: "sell_make_click"
    },
    sellModel: {
        event: "sell_model",
        eventCategory: "Seller_questions",
        eventAction: "sell_model_click"
    },
    sellYear: {
        event: "sell_year",
        eventCategory: "Seller_questions",
        eventAction: "sell_year_click"
    },
    b2cMobileNumber1Digit: {
        event: "b2c_mobile_number_1digit",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "mobile_number_1digit"
    },
    sellNumberProceed: {
        event: "sell_number_proceed",
        eventCategory: "Seller_questions",
        eventAction: "sell_number_proceed_click"
    },
    enterMobileNumber: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "Enter Mobile Number"
    },
    verify: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "Verify"
    },
    otpEntered: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "otp_entered"
    },
    odoSubmitClick: {
        event: "sell_odometer_submit",
        eventCategory: "Seller_questions",
        eventAction: "sell_odometer_submit_click"
    },
    carSpecsSubmitClick: {
        event: "sell_car_specs",
        eventCategory: "Seller_questions",
        eventAction: "sell_car_specs_click"
    },
    otherInfoSubmitClick: {
        event: "sell_other_info",
        eventCategory: "Seller_questions",
        eventAction: "sell_other_info_click"
    }
};

export const SELLER_DESKTOP_EVENTS = {
    homePageLoad: "homePageLoad",
    sellDownloadClick: "sellDownloadClick",
    sellAppProceedClick: "sellAppProceedClick",
    sellFAQsClick: "sellFAQsClick",
    sellFAQsViewMore: "sellFAQsViewMore",
    sellGetCarPrice: "sellGetCarPrice",
    sellMake: "sellMake",
    sellModel: "sellModel",
    sellYear: "sellYear",
    b2cMobileNumber1Digit: "b2cMobileNumber1Digit",
    sellNumberProceed: "sellNumberProceed",
    enterMobileNumber: "enterMobileNumber",
    verify: "verify",
    otpEntered: "otpEntered",
    odoSubmitClick: "odoSubmitClick",
    carSpecsSubmitClick: "carSpecsSubmitClick",
    otherInfoSubmitClick: "otherInfoSubmitClick"
};
