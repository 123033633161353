import desktopEventsAE from "./ae.desktop-events";
import desktopSellerEventsAE from "./ae.desktop-seller-events";
import { trackCustomEvents } from "../custom-event";

// DESKTOP
export const trackDesktopCustomEventsAE = (category, extras = {}) => {
    if (window) {
        window.setTimeout(() => {
            trackCustomEvents({
                platformEvents: desktopEventsAE,
                category
            }, extras);
        }, 0);
    }
};

export const trackDesktopMultipleEventsAE = (category, params, extras = {}) => {
    if (window) {
        window.setTimeout(() => {
            trackCustomEvents({
                platformEvents: desktopEventsAE,
                category, params
            }, extras);
        }, 0);
    }
};

export const trackDesktopSellerCustomerEvents = (category, extras = {}) => {
    if (window) {
        window.setTimeout(() => {
            trackCustomEvents({
                platformEvents: desktopSellerEventsAE,
                category
            }, extras);
        }, 0);
    }
};
